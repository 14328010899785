<template>
    <div class="custom-legend">
        <el-checkbox v-if="combine" class="btn first-btn"
                     :value="isCombine" @change="handleCombine">组合
        </el-checkbox>
        <el-checkbox class="btn sec-btn" :indeterminate="allCheckIndeterminate"
                     :value="checkAll" @change="handleCheckAllChange">全选
        </el-checkbox>
        <div class="group-legend">
            <el-checkbox v-for="(item, index) in groupVarieties" :key="item.label" :indeterminate="false"
                         :value="item.isChecked"
                         @change="handleGroupChange(index)">{{item.label}}
            </el-checkbox>
        </div>
        <div class="el-checkbox-group">
            <el-checkbox v-for="(item, index) in allVarieties" :value="item.isChecked" :label="item.name" :key="index"
                         @change="handleSingleChange(index)">{{item.name}}
            </el-checkbox>
        </div>
    </div>
</template>
<script>
    export default {
      name: "VarietyPicker",
      props: {
        combine: {
          type: Boolean,
          default: () => false
        }
      },
      data () {
        return {

        }
      },
      computed: {
        isCombine () {
          return this.$store.state.isCombine
        },
        checkAll () {
          return this.$store.state.checkAll
        },
        groupVarieties () {
          return this.$store.state.groupVarieties
        },
        allVarieties () {
          return this.$store.state.allVarieties
        },
        checkedVarieties () {
          return this.$store.getters.checkedVarieties
        },
        allCheckIndeterminate () {
          return this.checkedVarieties.length>0 && this.checkedVarieties.length< this.allVarieties.length
        }
      },
      mounted () {
      },
      methods: {
        handleCombine () {
          this.$store.commit('SET_IS_COMBINE', !this.isCombine)
          this.$emit('varietyChange');
        },
        handleCheckAllChange () {
          const allVarieties = JSON.parse(JSON.stringify(this.allVarieties))
          allVarieties.forEach((item, index) => {
            index===0?item.isChecked = true:item.isChecked = !this.checkAll
          })
          const groupVarieties = JSON.parse(JSON.stringify(this.groupVarieties))
          groupVarieties.forEach(item => {
            item.isChecked = !this.checkAll
          })
          this.$store.commit('SET_GROUP_VARIETIES', groupVarieties)
          this.$store.commit('SET_ALL_VARIETIES', allVarieties)
          this.$store.commit('SET_CHECK_ALL', !this.checkAll)
          this.$emit('varietyChange');
        },
        handleChecked() {},
        handleGroupChange (index) {
          const groupVarieties = JSON.parse(JSON.stringify(this.groupVarieties))
          groupVarieties[index].isChecked = !groupVarieties[index].isChecked
          this.$store.commit('SET_GROUP_VARIETIES', groupVarieties)
          this.$store.dispatch('groupCheck', index)
          this.$emit('varietyChange');
        },
        handleSingleChange (index) {
          const allVarieties = JSON.parse(JSON.stringify(this.allVarieties))
          if (this.checkedVarieties.length === 1 && allVarieties[index].isChecked) {
            this.$message({
              message: '请至少选择一个品种。',
              type: 'warning'
            })
            return false
          }
          allVarieties[index].isChecked = !allVarieties[index].isChecked
          this.$store.commit('SET_ALL_VARIETIES', allVarieties)
          const groups = allVarieties.filter(item => {
            return item.group === allVarieties[index].group
          })
          const bool = groups.every(item => item.isChecked)
          const groupVarieties = JSON.parse(JSON.stringify(this.groupVarieties))
          groupVarieties.forEach(item => {
            if (item.key === allVarieties[index].group) {
              item.isChecked = bool
              item.indeterminate = !bool && groups.some(item => item.isChecked)
            }
          })
          this.$store.commit('SET_GROUP_VARIETIES', groupVarieties)
          this.$emit('varietyChange');
        }
      }
    }
</script>
<style lang="scss" scoped>
    .custom-legend {
        position: relative;
        margin: 20px 0;

        .el-button {
            position: absolute;
            left: 0;
            top: 0;
            width: 60px;
            height: 30px;
            padding: 0;
            border: none;
        }

        .btn {
            padding: 0 5px;
            position: absolute;
            left: 0;
            top: 0;
            width: 60px;
            height: 30px;
            border: 1px solid #409EFF;
            border-radius: 4px;
            line-height: 30px;

            &.active {
                border: 1px solid #aa0000;
            }
        }

        .sec-btn {
            top: 40px;
        }

        .is-checked.active {
            .el-checkbox__inner {
                background-color: #AA0000;
                border-color: #AA0000;
            }

            .el-checkbox__label {
                color: #AA0000;
            }
        }

        .el-checkbox-group {
            text-align: start;
            padding: 0 40px 0 80px;
            line-height: 22px;
            font-size: 12px;

            .el-checkbox {
                width: 100px;
                margin-right: 0;
            }

        }

        .group-legend {
            text-align: left;
            height: 30px;
            margin-left: 80px;
            margin-bottom: 10px;
            border-bottom: 1px solid #999999;
        }
    }
</style>
